import goToURL from "./helpers/goToURL";

function init() {
    const logoutIcon = document.querySelectorAll(".logoutIcon");
    if (logoutIcon) {
        logoutIcon.forEach(function(logout) {
            logout.addEventListener("click", function (e) {
                e.preventDefault();

                if (window.janrain) {
                    window.janrain.capture.ui.endCaptureSession();
                } else {
                    const url = new URL(window.location.href);
                    url.searchParams.set("mgnlLogout", "true");
                    goToURL(url);
                }
                localStorage.removeItem("cart");
            });

        });
    }
}

export default { init };
