function init() {
    placeholder();
}

function placeholder() {
    let element = document.querySelectorAll(".form-item-l");
    element.forEach((el) => {
        let context = el.querySelectorAll("label")[0];
        let placeholderText = context.innerText.replace(/\s/g, "");
        el.querySelector("input").setAttribute("placeholder", placeholderText);
    });
}

export default { init };
