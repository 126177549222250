function init() {
    const storeListings = document.querySelectorAll(".storeListing .storeListing__title");
    storeListings.forEach((storeListing) => {
        const modal = storeListing.closest(".modal__parent");
        if (modal) {
            modal.setAttribute("aria-labelledby", storeListing.id);
        }
    });
}

export default { init };
