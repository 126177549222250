const HISTORY_KEY = "__mag_cms_recent_page_history__";

function init() {
    let localHistory = JSON.parse(localStorage.getItem(HISTORY_KEY) || "[]");
    let duplicate = localHistory.find((h) => h.url === window.location.href);

    if (!duplicate) localHistory.push({ url: window.location.href, title: document.title ? document.title : "(no title)", time: Date.now() });

    try {
        localStorage.setItem(HISTORY_KEY, JSON.stringify(localHistory));
    } catch (ex) {
        console.error("Could not add page history: " + ex); //eslint-disable-line
    }
}

export default { init };
