import * as yup from "yup";
import isPostalCode from "validator/lib/isPostalCode";

export default function (Alpine) {
    const isFormValid = (schema, data) => schema.isValidSync(data);

    const getFormErrors = async (schema, data) => {
        let resp = {};

        await schema.validate(data, { abortEarly: false }).catch(function (err) {
            err.inner.forEach((e) => {
                resp[e.path] = { ...e, hasError: true };
            });
        });

        return resp;
    };

    yup.addMethod(yup.string, "postalCode", function format(message) {
        return this.test({
            name: "postalCode",
            message,
            test: (value) => {
                const country = document.querySelector("html").lang.split("-").reverse()[0];
                return isPostalCode(value, country);
            },
        });
    });

    const rangerExport = { yup, isFormValid, getFormErrors };

    Alpine.magic("ranger", () => {
        return rangerExport;
    });
}
