import Alpine from "alpinejs";

Alpine.data("datetime", () => {
  return {
    isValidDate() {
      const timestamp = Date.parse(this.isoDateTime);
      return !isNaN(timestamp);
    },
    get isoDateTime() {
      return this.$el.getAttribute('datetime');
    },
    get format() {
      return this.$el.getAttribute('format');
    },
    get dateDisplay() {
      if (!this.isValidDate()) {
        return '';
      }

      const dateTime = new Date(this.isoDateTime);
      return this.$dateFormat(dateTime, this.format);
    }
  };
});
