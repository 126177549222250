import { pushEvent } from "../track";

const BASE_VIDEO_EVENT_OBJ = { interaction_type: "video" };

export const VIDEO = {
    view: "video_view",
    start: "video_start",
    progress: (pct) => `video_progress_${pct}%`,
    error: "video_error",
    finish: "video_finish",
};

export function pushVideoEvent(options) {
    pushEvent({
        ...BASE_VIDEO_EVENT_OBJ,
        ...options,
    });
}
