import { breakpointIs } from "./breakpoint";
import Alpine from "alpinejs";

document.addEventListener("alpine:init", () => {
    Alpine.data("stickyHeader", () => ({
        init() {
            this.setHeaderTop();
        },
        setHeaderTop() {
            const endorsementbarClass = breakpointIs("lg") ? ".endorsementBar.lg\\:sticky" : ".endorsementBar.sticky";
            const endorsementBar = document.querySelector(endorsementbarClass);
            const header = document.querySelector("header.sticky");
            if (header && endorsementBar) {
                header.style.top = endorsementBar.offsetHeight + "px";
            }
        },
    }));
});
