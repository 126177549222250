import Alpine from "alpinejs";
import { pathOr } from "ramda";
import { breakpointIs } from "../modules/breakpoint";

Alpine.magic("asyncForEach", () => async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
});

Alpine.magic("pathOr", () => (...params) => {
    return pathOr(...params);
});

Alpine.magic("breakpointIs", () => (size) => {
    return breakpointIs(size);
});
