import suiPersonalization from "../../personalization";
import Alpine from "alpinejs";

const dataFN = (config) => {
    return {
        ...config,
        step: 1,
        init() {
            const elm = this.$el;
            const preferences = suiPersonalization().getPreferences();

            if (preferences && preferences.categories) {
                preferences.categories.forEach(function (prefCookieCategories) {
                    const chkCategoryElm = elm.querySelector("[x-data=contentList] input[id='" + prefCookieCategories + "']");
                    if (chkCategoryElm)
                        chkCategoryElm.checked = true;
                });
            }
        },
        selectCategory() {
            const chkElm = this.$el;
            if (typeof chkElm.checked !== "undefined") {
                if (!chkElm.checked) {
                    chkElm.form.querySelector("input[name=category]").value = "";
                }
                chkElm.form.submit();
            }
        },
        selectAllInit(categoryGroup) {
            const elem = this.$el;
            const categories = elem.form.querySelectorAll("[data-categoryGroup='" + categoryGroup + "']");
            const selectedCategories = elem.form.querySelectorAll("[data-categoryGroup='" + categoryGroup + "']:checked");
            // Check Select All when all categories are checked/selected
            elem.checked = categories.length == selectedCategories.length;
        },
        selectAllCategory(elem, categoryGroup) {
            const categories = elem.form.querySelectorAll("[data-categoryGroup='" + categoryGroup + "']");
            categories.forEach(function (category) {
                // Manually check/uncheck category filter
                if (elem.checked) {
                    category.checked = true;
                } else {
                    category.checked = false;
                }
            });
            elem.form.submit();
        },
        selectConsumption(elem) {
            elem.form.consumption.forEach((checkbox) => {
                if (checkbox != elem) {
                    checkbox.checked = false;
                }
            });

            elem.form.submit();
        },
        collectCategories(elem) {
            const categoryId = elem.value;

            if (elem.checked) {
                suiPersonalization().selectCategory(categoryId);
            } else {
                suiPersonalization().removeCategory(categoryId);
            }
        },
        redirectAfterSubmit(href) {
            window.location = href;
        },
        savePartialRegistration() {
            this.inputElements = [...this.$el.querySelectorAll("input[data-schema]")];
            this.inputElements.forEach((elem) => {
                suiPersonalization().savePartialRegistration(elem);
            });
        },
        resetFilter(url) {
            const form = this.$el.closest("form");
            // reset all categories
            const categories = form.category || [];
            categories.forEach((category) => {
                const categoryId = category.value;
                suiPersonalization().removeCategory(categoryId);
            });
            window.location = url;
        },
    };
};

Alpine.data("contentList", dataFN);
window.sui = window.sui || {};
window.sui.contentList = dataFN;
