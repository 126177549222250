//Add pause events for autoplay carousels for dam/bc
export function setPauseButton(videoDiv) {
    let carousel = videoDiv.closest(".carousel__wrap");
    if (carousel) {
        if (carousel.getAttribute("data-autoplay") === "true") {
            videoDiv.addEventListener("click", function () {
                let carouselId = carousel.dataset.uuid;
                let pauseBtnParent = document.querySelector(`[data-uuid="${carouselId}"]`);
                let pauseBtn = pauseBtnParent.querySelector(".carousel__autoplayBtn");
                let action = pauseBtn.getAttribute("data-action");
                if (pauseBtn && action === "stop") {
                    pauseBtn.click();
                }
            });
        }
    }
}