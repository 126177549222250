function init() {
    openNav();
    closeNav();
    openSubNav();
    backToMain();
    setNavScrollToggle();
    setSubNavHeightToggle();
    subNavOverlap();
}

function setSubNavHeightToggle() {
    const mainSubNav = document.querySelectorAll(".subNav--0:not(.subNav--mobile)");
    if (!mainSubNav) return;
    mainSubNav.forEach(function (mainNav) {
        const subNavItems = mainNav.querySelectorAll(".subNav__item");
        subNavItems.forEach(function (item) {
            ["mouseover", "focusin"].forEach(function (eventType) {
                item.addEventListener(eventType, function (e) {
                    const subNavInner = item.querySelector(".subNav__container");
                    const subNavList = item.querySelector(".subNav__list");
                    const header = document.querySelector("header");
                    const remainingWindowHeight = window.innerHeight - header.offsetHeight;
                    const navLi = e.target.closest(".subNav__item");
                    const navList = item.closest(".subNav__list");
                    const navLiSubNav = navLi.querySelector(".subNav");
                    const navLiSubNavBg = navLi.querySelector(".subNav__bg");
                    if (subNavList && subNavList.scrollHeight >= remainingWindowHeight) {
                        mainNav.style.height = remainingWindowHeight + "px";
                        document.body.style.overflowY = "hidden";

                        if (navLiSubNav && navLiSubNavBg) {
                            navLiSubNav.style.overflowY = "scroll";
                            navLiSubNavBg.style.height = navLiSubNav.scrollHeight + "px";
                        }
                    } else {
                        mainNav.style.height = subNavList ? subNavList.scrollHeight + getPaddingHeight(subNavInner) + "px" : "auto";
                        document.body.style.overflow = null;

                        if (navLiSubNav && navLiSubNavBg) {
                            navLiSubNav.style.overflowY = null;
                            navLiSubNavBg.style.height = null;
                        }
                    }

                    if (navLiSubNav && navLiSubNavBg) {
                        navList.style.overflowY = "hidden";
                    }
                });
            });

            ["mouseleave"].forEach(function (eventType) {
                item.addEventListener(eventType, function (e) {
                    const navList = e.target.closest(".subNav__list");
                    navList.style.overflowY = "auto";
                    mainNav.style.height = "auto";
                });
            });
        });
    });
}

function getPaddingHeight(element) {
    let styles = element.currentStyle || window.getComputedStyle(element);
    let paddingTop = styles.paddingTop.replace("px", "");
    let paddingBottom = styles.paddingBottom.replace("px", "");
    return paddingTop * 1 + paddingBottom * 1;
}

function openNav() {
    const navButton = document.querySelector(".nav__mobile__button");
    if (navButton) {
        navButton.addEventListener("click", toggleMenu);
    }
}

function closeNav() {
    const closeButton = document.querySelector(".nav__mobile__close");
    if (closeButton) {
        closeButton.addEventListener("click", toggleMenu);
    }
}

function openSubNav() {
    const subNavButtons = document.querySelectorAll(".subNav__itemNextButton");
    subNavButtons.forEach(function (subNavButton) {
        subNavButton.addEventListener("click", function () {
            const subNavItem = getParentNode(subNavButton, ".subNav__item") || getParentNode(subNavButton, ".nav__item");
            if (!subNavItem) return;
            const subNavMobile = subNavItem.querySelector(".subNav--mobile");
            toggleSubMenu(subNavMobile);
            const navMobileList = document.querySelector(".nav__mobile__navList");
            if (navMobileList.classList.contains("overflow-y-auto")) {
                navMobileList.classList.remove("overflow-y-auto");
            }
        });
    });
}

function backToMain() {
    const backButtons = document.querySelectorAll(".subNav__back");
    backButtons.forEach(function (backButton) {
        backButton.addEventListener("click", function () {
            const subMenu = this.closest(".subNav--mobile");
            toggleSubMenu(subMenu);
            const subMenuLi = subMenu.closest("li");
            if (subMenuLi.classList.contains("nav__item")) {
                const navMobileList = document.querySelector(".nav__mobile__navList");
                navMobileList.classList.add("overflow-y-auto");
            }
        });
    });
}

function toggleMenu() {
    const navMenu = document.querySelector(".nav__mobile__menu");
    navMenu.classList.toggle("nav__mobile__menu--open");
}

function toggleSubMenu(subMenu) {
    subMenu.classList.toggle("subNav--open");
}

function setNavScrollToggle() {
    const body = document.querySelector("body");
    const nav = body.querySelector("nav");
    const navMobile = document.querySelector(".nav__mobile__menu");
    if (!nav) return;
    window.addEventListener("scroll", function () {
        if (this.scrollY) {
            body.classList.add("nav-stuck");
            nav.classList.add("darkMode-dark");
            nav.classList.add("js-active");
            nav.classList.remove("darkMode-light");
            if (navMobile) {
                navMobile.classList.add("mt-0");
            }
        } else {
            body.classList.remove("nav-stuck");
            nav.classList.remove("darkMode-dark");
            nav.classList.remove("js-active");
            nav.classList.add("darkMode-light");
            if (navMobile) {
                navMobile.classList.remove("mt-0");
            }
        }
    });
}

function getParentNode(element, className) {
    const parent = element.closest(className);
    return parent;
}

function subNavOverlap() {
    const subNav2Items = document.querySelectorAll(".subNav__item");
    subNav2Items.forEach(function (nav2Item) {
        const subNav2 = getParentNode(nav2Item, ".subNav--2");
        const subNav1 = getParentNode(nav2Item, ".subNav--1");
        const hasNextBtn = nav2Item.querySelector(".subNav__itemNextButton");
        if (!subNav1 || !subNav2 || !hasNextBtn) return;

        nav2Item.addEventListener("mouseover", function () {
            subNav1.classList.add("subNav__fourthLevel");
            subNav2.classList.add("subNav__fourthLevel");
        });

        nav2Item.addEventListener("mouseleave", function () {
            subNav1.classList.remove("subNav__fourthLevel");
            subNav2.classList.remove("subNav__fourthLevel");
        });

        const subNav3 = getParentNode(nav2Item, ".subNav--3");
        if (!subNav3) return;

        const subNav3Items = subNav3.querySelectorAll(".subNav__item");
        subNav3Items.forEach(function (nav3Item) {
            const nav3 = getParentNode(nav3Item, ".subNav--3");
            nav3Item.addEventListener("mouseover", function () {
                subNav1.classList.add("subNav__fifthLevel");
                subNav2.classList.add("subNav__fifthLevel");
                nav3.classList.add("subNav__fourthLevel");
            });

            nav3Item.addEventListener("mouseleave", function () {
                subNav1.classList.remove("subNav__fifthLevel");
                subNav2.classList.remove("subNav__fifthLevel");
                nav3.classList.remove("subNav__fourthLevel");
            });

            const subNav4 = getParentNode(nav3Item, ".subNav--4");
            const nav3HasNextBtn = nav3Item.querySelector(".subNav__itemNextButton");
            if (!subNav4 || !nav3HasNextBtn) return;

            const subNav4Items = subNav4.querySelectorAll(".subNav__item");
            subNav4Items.forEach(function (nav4Item) {
                const nav4 = getParentNode(nav4Item, ".subNav--4");
                nav4Item.addEventListener("mouseover", function () {
                    nav3.classList.add("subNav__fifthLevel");
                    nav4.classList.add("subNav__fourthLevel");
                });

                nav4Item.addEventListener("mouseleave", function () {
                    nav3.classList.remove("subNav__fifthLevel");
                    nav4.classList.remove("subNav__fourthLevel");
                });
            });
        });
    });
}

export default { init };
