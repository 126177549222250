import Alpine from "alpinejs";

Alpine.magic("distanceInMiles", () => (a, b) => {
    let distanceInMeters = window.google.maps.geometry.spherical.computeDistanceBetween(a, b);
    let distanceInMiles = (distanceInMeters * 0.00062137).toFixed(1);
    if (distanceInMiles.substring(distanceInMiles.length - 1) == "0") distanceInMiles = distanceInMiles.substring(0, distanceInMiles.length - 2);
    return distanceInMiles + " miles";
});

Alpine.magic("distanceInKm", () => (a, b) => {
    let distanceInMeters = window.google.maps.geometry.spherical.computeDistanceBetween(a, b);
    distanceInMeters = (distanceInMeters / 1000).toFixed(1);
    if (distanceInMeters.substring(distanceInMeters.length - 1) == "0") distanceInMeters = distanceInMeters.substring(0, distanceInMeters.length - 2);
    return distanceInMeters + " km";
});

Alpine.magic("createAddress", () => (obj) => {
    let fullAddress = obj.address1 ? obj.address1 + ", " : "";
    if (Object.prototype.hasOwnProperty.call(obj, "address2")) {
        fullAddress = obj.address1 + " " + obj.address2 + ", ";
    }
    let city = obj.city ? obj.city + ", " : "";
    let zoneCode = obj.zoneCode ? obj.zoneCode + " " : "";
    let postalCode = obj.postalCode ? obj.postalCode : "";
    let country = obj.country ? " " + obj.country : "";
    return fullAddress + city + zoneCode + postalCode + country;
});

Alpine.magic("getPostalCode", () => (address) => {
    const matches = address.match(/\b[A-Z]{2}\s+\d{5}(-\d{4})?\b/);
    if (matches) {
        return matches[0];
    }
    return "";
});

Alpine.magic("getFirstAddress", () => (results) => {
    let address = [];
    if (results[0].address.address1) address.push(results[0].address.address1);
    if (results[0].address.city) address.push(results[0].address.city);
    if (results[0].address.postalCode) {
        let postalCode =  results[0].address.postalCode;
        if (results[0].address.zoneCode) postalCode = results[0].address.postalCode + " " + postalCode;
        address.push(postalCode);
    }
    if (results[0].address.zoneCode) 
    return address.join(", ");
});

Alpine.magic("getDirectionLink", () => (lat, long) => ("https://www.google.com/maps?saddr=My+Location&daddr=" + lat + "," + long));