import Alpine from "alpinejs";
import whenDomReady from "when-dom-ready";

const dataFN = (config) => {
    return {
        ...config,
        init(){
            const _this = this;
            whenDomReady(function(){
                _this.getStickyHeaderHeight();
            });
        },
        resize() {
            this.getStickyHeaderHeight();
        },
        getStickyHeaderHeight() {
            const stickyHeader = document.querySelector("header.sticky"); // Get sticky header's height
            if (stickyHeader) {
                let offsetHeight = stickyHeader.offsetHeight;
                if (offsetHeight == 0) { // Header height is 0 when header communication is used
                    const headerComStickyHeader = document.querySelector("header.sticky > div"); // Use header child div to get header communication height
                    if (headerComStickyHeader) {
                        offsetHeight = headerComStickyHeader.offsetHeight;
                    }
                }
                this.$el.style.top = offsetHeight + "px";
            }
        }
    }
};

Alpine.data("sticky", dataFN);
window.sui = window.sui || {};
window.sui.sticky = dataFN;
