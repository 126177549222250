import { detect } from "detect-browser";

export const isBrowserSupported = () => {
    // return true if browser is supported. Today we only kickout IE browsers
    const browser = detect();
    const notSupportedNames = ["ie"];
    const isSupported = !notSupportedNames.includes(browser.name);
    return isSupported;
};

export const addHtmlSupportClass = () => {
    // Add HTML class telling us if current browser is supported
    // See light-modules/sanofi-lm-platform/themes/sanofi-platform/src/css/modules/hasBrowserSupport.scss so premade css classes
    const isSupportedClass = isBrowserSupported() ? "browser-supported" : "browser-not-supported";
    document.documentElement.classList.add(isSupportedClass);
};
