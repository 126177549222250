import { get, getJSON, set, remove } from 'js-cookie';

export const getCookie = (name) => {
  return get(name);
};

export const getCookieJson = (name) => {
  return getJSON(name);
};

export const setCookie = (name, value, options = {}) => {
  const secureOptions = { ...options, secure: true };
  set(name, value, secureOptions);
};

export const removeCookie = (name, options = {}) => {
  const secureOptions = { ...options, secure: true };
  remove(name, secureOptions);
};
