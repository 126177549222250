import Alpine from "alpinejs";

import { scrollTo } from "../../scrollTo";
import { breakpointIs } from "../../breakpoint";

const dataFN = (config) => {
    const opts = { open: false, ...config };

    return {
        open: opts.open,
        scrollTo(id) {
            scrollTo(id);
        },
        setActiveLink(el, className) {
            let anchors = document.querySelectorAll("li.anchorItem");

            anchors.forEach((anchor) => {
                anchor.classList.remove(className);
            });

            el.classList.add(className);
        },

        setActiveLinkByID(id) {
            let anchors = document.querySelectorAll("li.anchorItem");
            anchors.forEach((anchor) => {
                let anchorID = anchor.getAttribute("id");
                if (anchorID === `link_${id}`) {
                    this.setActiveLink(anchor, "contentStyleActiveAnchorLink");
                }
            });
        },
        scrollTop() {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        },
        scrollTopBtnObserver() {
            const options = {
                root: null,
                rootMargin: "0px 0px -100% 0px",
                threshold: 0,
            };
            const observer = new IntersectionObserver((entries) => {
                const scrollTopBtn = document.querySelector(".scrollTopBtn");
                const classes = ["!opacity-100", "-translate-y-6"];

                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        scrollTopBtn?.classList.add(...classes);
                    } else {
                        scrollTopBtn?.classList.remove(...classes);
                    }
                });
            }, options);
            const main = document.querySelector("main");
            observer.observe(main);
        },
        getAnchorItems() {
            const anchorsSelector = breakpointIs("lg") ? "li.anchorItem" : "span.mobileAnchor";
            return document.querySelectorAll(anchorsSelector);
        },
        anchorNavInit() {
            this.getAnchorItems();
        },
    };
};

Alpine.data("anchorMenu", dataFN);
window.sui = window.sui || {};
window.sui.anchorMenu = dataFN;
