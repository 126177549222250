import isEditMode from "../../isEditMode";
import Alpine from "alpinejs";

const dataFN = (config) => ({
    ...config,
    open: isEditMode,
    newCart: "",
    siteOverride: config.siteOverride || null,
    successPageLink: config.successPageLink,
    maxMessageDisplay: 3,
    messageDisplayList: [],
    addedToCartText: config.addedToCartText || "",
    removedFromCartText: config.removedFromCartText || "",
    selectQuantity: 0,
    hasCaptchaError: false,
    cart: JSON.parse(localStorage.getItem("cart") || "[]"),

    formSubmitError: "",
    touchedElements: [],
    isFormSubmitted: false,
    isLoading: false,
    get isCartEmpty() {
        return this.cart.length === 0;
    },
    get isCartHasCustomDestination() {
        return !(this.cart.find(item => item.toGroups.length > 0) === undefined); // true if any item has group(s) configured in the 'toGroups' prop, false if not
    },
    async getFormErrors() {
        const orderInfoErrors = await this.$ranger.getFormErrors(
            this.$ranger.yup.object().shape(this.schemas.orderInfo),
            this.formData.orderInfo
        );
        const checkboxesErrors = await this.$ranger.getFormErrors(
            this.$ranger.yup.object().shape(this.schemas.checkboxes),
            this.formData.checkboxes
        );
        const formErrors = { orderInfo: orderInfoErrors };
        if (Object.keys(checkboxesErrors).length > 0) {
            formErrors.checkboxes = checkboxesErrors;
        }
        this.formErrors = formErrors;
    },
    isFormValid() {
        const isOrderInfoValid = this.$ranger.isFormValid(this.$ranger.yup.object().shape(this.schemas.orderInfo), this.formData.orderInfo);
        const isCheckboxesValid = this.$ranger.isFormValid(this.$ranger.yup.object().shape(this.schemas.checkboxes), this.formData.checkboxes);
        return isOrderInfoValid && isCheckboxesValid;
    },
    onFormChange() {
        const model = this.$el.name;
        if (!this.touchedElements.includes(model)) {
            this.touchedElements.push(model);
        }

        if (this.isFormSubmitted) {
            this.getFormErrors();
        }
    },

    formErrors: {},
    formData: {
        checkboxes: {},
        orderInfo: {},
    },
    schemas: {
        checkboxes: {},
        orderInfo: {},
    },

    get cartIndex() {
        return this.cart.length;
    },
    getQtyOptions(options) {
        const list = [];
        for (let option of options) {
            list.push({
                value: option.value,
                text: option.text,
            });
        }
        return list;
    },
    cartTotal() {
        let cart = JSON.parse(localStorage.getItem("cart") || "[]");
        return cart.map((item) => item.price * item.quantity).reduce((prev, curr) => prev + curr, 0);
    },
    addToCartPrice(id, title, quantity, image, $dispatch) {
        if (quantity > 0) {
            let cart = JSON.parse(localStorage.getItem("cart") || "[]");
            const cartItemIndex = cart.findIndex((item) => item.id === id);
            let toGroups = this.$refs[`select-${id}`].getAttribute("data-toGroups").split(",");

            if (cartItemIndex >= 0) {
                let price = this.$refs[`select-${id}`].getAttribute("data-price");
                let min = this.$refs[`select-${id}`].getAttribute("data-min");
                let max = this.$refs[`select-${id}`].getAttribute("data-max");
                cart[cartItemIndex] = { ...cart[cartItemIndex], quantity, price: price, min: min, max: max, toGroups };
            } else {
                let price = 0;
                let min = 0;
                let max = 999;
                let quantityOptions = [];
                price = this.$refs[`select-${id}`].getAttribute("data-price");
                min = this.$refs[`select-${id}`].getAttribute("data-min");
                max = this.$refs[`select-${id}`].getAttribute("data-max");
                quantityOptions = [];
                cart = [...cart, { id, title, quantity, price, min, max, image, quantityOptions, toGroups }];
            }

            localStorage.setItem("cart", JSON.stringify(cart));
            $dispatch("on-cart-update");
            this.displayConfirmMessage("added");
        } else {
            this.removeFromCart(id, $dispatch);
        }
    },
    addToCart(id, title, quantity, image, $dispatch, groups = "") {
        if (quantity > 0) {
            let cart = JSON.parse(localStorage.getItem("cart") || "[]");
            const cartItemIndex = cart.findIndex((item) => item.id === id);
            const areGroupsEmpty = groups.indexOf(",") === -1 && groups === "";
            const toGroups = areGroupsEmpty ? [] : groups.split(",");
            if (cartItemIndex >= 0) {
                cart[cartItemIndex] = { ...cart[cartItemIndex], quantity };
            } else {
                const quantityOptions = this.$refs[`select-${id}`].options ? this.getQtyOptions(this.$refs[`select-${id}`].options) : [];
                cart = [...cart, { id, title, quantity, image, quantityOptions, toGroups }];
            }

            localStorage.setItem("cart", JSON.stringify(cart));
            $dispatch("on-cart-update");
            this.displayConfirmMessage("added");
        } else {
            this.removeFromCart(id, $dispatch);
        }
    },
    removeFromCart(id, $dispatch) {
        const cart = JSON.parse(localStorage.getItem("cart") || "[]");
        if (cart.find((item) => item.id == id)) {
            // display removed from cart message
            this.displayConfirmMessage("removed");
        }
        this.cart = cart.filter((item) => item.id !== id);
        localStorage.setItem("cart", JSON.stringify(this.cart));
        $dispatch("on-cart-update");
    },
    refreshCart() {
        const cart = JSON.parse(localStorage.getItem("cart") || "[]");
        cart.forEach((item) => {
            item.title = decodeURIComponent(item.title);
        });

        this.cart = cart;
        if (this.cart.length == 0) window.location.reload();
    },
    isCaptchaValid() {
        const response = window.grecaptcha.getResponse();
        const isCaptchaValid = response.length > 0 ? true : false;
        this.hasCaptchaError = !isCaptchaValid;
        return isCaptchaValid;
    },
    isAllCheckboxesSelected() {
        this.hasCaptchaError = this.isCaptchaValid();
        if (this.isFormSubmitted === true) {
            return this.formData.checkboxes.every((box) => box == true);
        }
    },
    async submitOrder() {
        this.formSubmitError = "";
        this.isFormSubmitted = true;

        if (!this.isFormValid() || !this.isCaptchaValid()) {
            await this.getFormErrors();
            return;
        }

        this.isLoading = true;

        const formData = this.formData.orderInfo;
        const cartItems = this.cart.map((data) => ({
            name: data.title.replace(/<\/?p[^>]*>/g, ""),
            quantity: data.quantity,
            price: data.price,
            toGroups: data.toGroups,
        }));
        formData.items = cartItems;
        formData.hasMultipleFulfillmentDestinations = this.isCartHasCustomDestination;
        formData["g-recaptcha-response"] = window.grecaptcha.getResponse();

        const postUrl = this.$refs.bestsellForm?.action;
        const options = {
            method: "POST",
            headers: this.getRequestHeader(),
            body: JSON.stringify(formData),
        };
        fetch(postUrl, options)
            .then(response => {
                if (response.ok) {
                    this.cart = [];
                    localStorage.setItem("cart", JSON.stringify([]));
                    window.location.href = this.$domPurify(this.successPageLink); // event.target.dataset.successpagelink;
                } else {
                    throw Error(response.statusText);
                }
            })
            .catch((error) => {
                this.formSubmitError = error;
            }).finally(() => {
                this.isLoading = false;
                window.grecaptcha.reset()
            });
    },
    getRequestHeader() {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        if (this.siteOverride) {
            headers.append("x-sny-siteoverride", this.siteOverride);
        }
        return headers;
    },
    inputChange(e) {
        const newFormData = config.formData;
        config.data = Object.keys(newFormData).map((key) => {
            if (key == e.target.name) {
                newFormData[key] = e.target.value;
            }
            return newFormData;
        });
    },
    getSelectedQty(id) {
        const cart = JSON.parse(localStorage.getItem("cart") || "[]");
        const foundItem = cart.find((item) => item.id == id);
        return foundItem ? foundItem.quantity : "";
    },
    isSelectedQty(id, quantity) {
        const cart = JSON.parse(localStorage.getItem("cart") || "[]");
        return cart.find((item) => item.id == id && item.quantity == quantity);
    },
    displayConfirmMessage(type) {
        const self = this;

        if (self.addedToCartText && self.removedFromCartText) {
            if (self.maxMessageDisplay == self.messageDisplayList.length) {
                // removed the oldest message
                self.messageDisplayList.shift();
            }
            // generate random id for the display message
            const randomId = (Math.random() + 1).toString(36).substring(7);
            const obj = { type, messageDisplay: type == "added" ? self.addedToCartText : self.removedFromCartText, id: randomId };
            self.messageDisplayList.push(obj);
            window.setTimeout(function () {
                // will close the confirm message after 5 seconds if the message still exist on the page
                self.messageDisplayList = self.messageDisplayList.filter((item) => item.id != randomId);
            }, 5000);
        }
    },
})

Alpine.data("cart", dataFN);
window.sui = window.sui || {};
window.sui.cart = dataFN;