import { setPauseButton } from "..";
import { pushVideoEvent, VIDEO } from "../events";

// html5
export function damPlayer(damVideo) {
    let title = damVideo.dataset?.title;
    let context = damVideo.dataset?.gtmcontext;
    let eventsPushed = {};

    setPauseButton(damVideo.parentElement);

    damVideo.addEventListener("loadedmetadata", function (e) {
        if (damVideo.closest(".tns-slide-cloned")) { // to avoid duplicate "view" push, due to carousel cloning of slides
            eventsPushed[VIDEO.view] = true;
        } else {
            if (!eventsPushed[VIDEO.view]) {
                pushVideoEvent({
                    event: VIDEO.view,
                    name: title,
                    length: e.target.duration,
                });
                eventsPushed[VIDEO.view] = true;
            }
        }
    });

    if (!eventsPushed[VIDEO.view]) {
        damVideo.load();
    }

    damVideo.addEventListener("timeupdate", function (e) {
        if (!eventsPushed[VIDEO.start] && !isNaN(e.target.duration)) {
            pushVideoEvent({
                event: VIDEO.start,
                name: title,
                length: e.target.duration,
                context: context,
            });

            eventsPushed[VIDEO.start] = true;
        }

        let pct = (e.target.currentTime / e.target.duration) * 100;
        let pctFloor = Math.floor(pct);
        if (pct >= 10 && pctFloor % 10 === 0) {
            if (!eventsPushed[pctFloor]) {
                eventsPushed[pctFloor] = true;
                pushVideoEvent({
                    event: VIDEO.progress(pctFloor),
                    name: title,
                    length: e.target.duration,
                    context: context,
                });
            }
        }

        //since looped videos don't end, this just emulates the 'ended' event firing
        if (e.target.loop === true && pctFloor > 90) {
            pushVideoEvent({
                event: VIDEO.progress(100),
                name: title,
                length: e.target.duration,
                context: context,
            });

            pushVideoEvent({
                event: VIDEO.finish,
                name: title,
                length: e.target.duration,
                context: context,
            });
        }
    });

    // Ended
    damVideo.addEventListener("ended", function (e) {
        eventsPushed = {};
        pushVideoEvent({
            event: VIDEO.finish,
            name: title,
            length: e.target.duration,
            context: context,
        });
    });

    // Error
    damVideo.addEventListener("error", function (e) {
        let error = e;

        // Chrome v60
        if (e.path && e.path[0]) {
            error = e.path[0].error;
        }

        // Firefox v55
        if (e.originalTarget) {
            error = error.originalTarget.error;
        }

        if (!eventsPushed[VIDEO.error]) {
            pushVideoEvent({
                event: VIDEO.error,
                name: title,
                error_type: error.message,
                context: context,
            });

            eventsPushed[VIDEO.error] = true;
        }
    });
}
