import Alpine from "alpinejs";

Alpine.magic("loadScript", () => (src, opts = { async: true, onLoad: () => {}, onError: () => {} }) => {
    if (!document.querySelector("script[src='" + src + "']")) {
        const script = document.createElement("script");
        script.src = src;
        script.async = opts.async;
        document.head.appendChild(script);

        script.addEventListener("load", () => {
            opts.onLoad();
        });
        
        script.addEventListener("error", (ev) => {
            opts.onError(ev);
        });
    }
    else {
        opts.onLoad();
    }
});
