import jsonFromDom from "./jsonFromDom";
import { trackEvent } from "./track";
import links from "./links";
import { escapeQuotes } from "./helpers/stringHelpers";

function leaveSitePrompt(e, target, modals) {
    const link = target.getAttribute("href");
    const modal = getModalWithBlacklistedUrl(modals, link);
    if (modal) {
        e.preventDefault();
        // Trigger Modal Dispatch
        window.dispatchEvent(new CustomEvent("open-modal", { detail: { id: modal.getAttribute("data-id") } }));
        // Set Proceed Button URL
        setProceedUrl(link, modal, target);
    } else{
        pushDataLayer(link);
        if (target.dataset.linktype == "download") {
            links.pushExternalDownloadEvent(target);
        }
    }
}

function pushDataLayer(link) {
    trackEvent({
        event: "outbound_link",
        url: link,
        interaction_type: "outbound_link"
    })
}

function getModalWithBlacklistedUrl(modals, href) {
    let modal;
    for (let i = 0; i < modals.length; i++) {
        let json = jsonFromDom(".leaveSitePrompt__data--" + modals[i].getAttribute("data-id"));
        if (json.filterType && isBlacklistedUrl(href, json)) {
            modal = modals[i];
            break;
        }
    }
    return modal;
}

function isBlacklistedUrl(href, json) {
    let isBlackListed;
    switch (json.filterType) {
        case "whitelist":
            isBlackListed = !json.list.find((url) => getUrlDomain(url) === getUrlDomain(href));
            break;
        case "blacklist":
            isBlackListed = json.list.find((url) => getUrlDomain(url) === getUrlDomain(href));
            break;
        default:
            isBlackListed = true;
    }
    return isBlackListed;
}

function getUrlDomain(link) {
    try {
        const url = new URL(link);
        return url.origin;
    } catch(e) {
        return link;
    }
}

function setProceedUrl(link, modal, target) {
    const button = modal.querySelector(".leaveSitePrompt__button");
    button.setAttribute("href", link);
    button.setAttribute("target", "_blank");
    button.dataset.linktype = target.dataset.linktype;
    button.dataset.linktext = escapeQuotes(target.innerText);
}

export { leaveSitePrompt };
