export const scrollToHash = () => {
    if (window.location.hash != "") {
        scrollTo(window.location.hash);
    }
}

export const scrollTo = (id) => {
    loadImagesBeforeTargetSection(id, () => {
        const stickyHeader = document.querySelector("header.sticky");
        const scrollToElement = document.getElementById(id.replace("#", ""));
        if (scrollToElement) {
            const scrollToElementPos = scrollToElement.getBoundingClientRect().top;
            const elementTopPos = scrollToElementPos - document.body.getBoundingClientRect().top;
            const topPos = stickyHeader ? elementTopPos - stickyHeader.offsetHeight : elementTopPos;
            window.scrollTo({
                top: topPos,
                behavior: "smooth",
            });
        }    
    });
}

function loadImagesBeforeTargetSection(id, callback) {
    const images = document.querySelectorAll("body img.lazyload");
    const imagesToLoad = [];
    for (let i=0; i<images.length; i++) {
        let image = images[i];
        /***********
            I used the `[id='123']` selector because the components use UUIDs as IDs, which start with a number (e.g., 83d94891-b8d5-4083-8176-f27bbfe9b43c). 
            JavaScript's querySelector doesn't support this type of ID. 
        ***********/
        if (image.closest("[id='" + id.replace("#", "") + "']")) {
            break; // Break the loop then the image is in the target scroll section
        }
        image.classList.remove("lazyload"); // remove lazyload class to stop lazysizes script to load the image automatically
        imagesToLoad.push(image);
    }

    loadImages(imagesToLoad).finally(callback);
}

function loadImages(images) {
    const promises = images.map(image => loadImage(image)); 
    return Promise.all(promises);
}

function loadImage(image) {
    window.lazySizes.loader.unveil(image); // Load Images

    return new Promise((resolve, reject) => {
        image.onload = () => {
            resolve();
        };
    
        image.onerror = () => {
            reject();
        };
    });
}