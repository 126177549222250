/* eslint-disable */
import Alpine from "alpinejs";

const dataFN = () => {
    return {
        _carouselID: "",
        isEditMode() {
            const isEditMode = document.querySelector("body").classList.contains("mgnl-admin");
            return isEditMode;
        },
        get carouselID() {
            return this._carouselID;
        },
        setCarouselID(id) {
            this._carouselID = id.toString();
        },

        getSlideContainer() {
            const container = document.querySelector(`[data-carousel-uuid='${this._carouselID}']`);
            return container.parentElement;
        },

        getSlides() {
            const container = this.getSlideContainer();
            return container.querySelectorAll(".carousel__slide");
        },
        getActiveSlide() {
            const container = this.getSlideContainer();
            return container.querySelector(".tns-slide-active");
        },
        setActiveItem(previousIndex, selectedIndex, currentSlide) {
            const thumbnail = document.getElementById(`thumbnail-${currentSlide.dataset.carouselUuid}-${previousIndex}`);
            const thumbnailClear = document.getElementById(`thumbnail-${currentSlide.dataset.carouselUuid}-${selectedIndex}`);
            thumbnail?.classList.replace('carousel__thumbnail', 'carousel__thumbnail--active');
            thumbnailClear?.classList.replace('carousel__thumbnail--active', 'carousel__thumbnail');
        },
        updateSlideStylesToActive(slide, index, currentActiveSlide) {
            //if adjusting carousel with thumbnails and pagination is available, update pagination
            let tnsSlide = currentActiveSlide.id.toString().split("-")[0];
            let clickTnsButton = document.querySelector(`button[data-nav="${index}"][aria-controls="${tnsSlide}"]`); 
            clickTnsButton?.click();

            //change current active slide to a regular slide
            currentActiveSlide.classList?.remove('tns-slide-active');
            slide.classList?.add('tns-slide-active');
            currentActiveSlide.ariaHidden = true;
            slide.ariaHidden = false;
            //transform to show active slide based on %
            let percentage = parseFloat((index / this.getSlides().length) * 100).toFixed(4);
            let container = this.getSlideContainer();
            container.style = `transform: translate3d(-${percentage}%, 0px, 0px)`;
        },
        setActiveCarouselSlide(selectedIndex) {
            if (!this.isEditMode()) { //disable features in author mode
                const carouselSlides = this.getSlides();
                const previousActiveSlide = this.getActiveSlide();

                let currentSlide = document.querySelector('.tns-slide-active');
                let previousIndex = parseInt(previousActiveSlide.id.toString().split("item")[1]);

                this.setActiveItem(selectedIndex, previousIndex, currentSlide);

                previousActiveSlide &&
                    previousIndex !== parseInt(selectedIndex) &&
                    carouselSlides.forEach((slide, index) => {
                        if (index === parseInt(selectedIndex)) {
                            this.updateSlideStylesToActive(slide, index, previousActiveSlide);
                            let prev = this.getButton("prev");
                            let next = this.getButton("next");
                            //buttons
                            if (selectedIndex === 0) {
                                if (prev) {
                                    prev.disabled = true;
                                }
                                if (next) {
                                    next.disabled = false;
                                }
                            } else if (selectedIndex === carouselSlides.length - 1) {
                                if (prev) {
                                    prev.disabled = false;
                                }
                                if (next) {
                                    next.disabled = true;
                                }
                            } else {
                                if (prev) {
                                    prev.disabled = false;
                                }
                                if (next) {
                                    next.disabled = false;
                                }
                            }
                        }
                    });
            }
        },

        getButton(type) {
            let classQuery = `.carousel__controls--${type}`;
            let btns = document.querySelectorAll(classQuery);
            let btn = null;
            //get the proper button for this carousel via aria-controls
            let container = this.getSlideContainer();
            btns.forEach((b) => {
                if (b.attributes["aria-controls"].nodeValue === container.id) {
                    btn = b;
                }
            });

            return btn;
        },
    };
};

Alpine.data("carousel", dataFN);
window.sui = window.sui || {};
window.sui.carousel = dataFN;
