import { pushVideoEvent, VIDEO } from "../events";

export function vimeoPlayer({ id, alpine, gtmContext = "other"}) {
  const setEventTracking = async () => {
    const player = new window.Vimeo.Player(id);

    // https://developer.vimeo.com/player/sdk/reference#get-the-title-of-a-video
    const videoTitle = await player.getVideoTitle();
    const videoDuration = await player.getDuration();
    const videoContext = gtmContext;
    const percentLogger = [];


    // https://developer.vimeo.com/player/sdk/reference#play
    player.on('play', function () {
      pushVideoEvent({
        event: VIDEO.start,
        name: videoTitle,
        length: videoDuration,
        context: videoContext,
      });
    });

    // https://developer.vimeo.com/player/sdk/reference#progress
    player.on('timeupdate', function ({ percent }) {
      const currPercent = Math.floor(percent * 100);
      if (currPercent > 0 && currPercent % 10 === 0 && !percentLogger.includes(currPercent)) {
        percentLogger.push(currPercent);

        pushVideoEvent({
          event: VIDEO.progress(currPercent),
          name: videoTitle,
          length: videoDuration,
          context: videoContext,
        });
      }
    });

    player.on('ended', function () {
      pushVideoEvent({
        event: VIDEO.finish,
        name: videoTitle,
        length: videoDuration,
        context: videoContext,
      });
    });

    player.on('error', function ({ message , name }){
      pushVideoEvent({
        event: VIDEO.error,
        name: videoTitle,
        error_type: name + " " + message,
        context: videoContext,
      });
    });
  };


  // Make sure Vimeo is defined and available... no init callback from Vimeo :(
  const hasVimeo = () => {
    setTimeout(function () {
      if (window.Vimeo) {
        setEventTracking(id);
      } else {
        hasVimeo();
      }
    }, 100);
  };

  //Load vimeo script
  alpine.$loadScript("https://player.vimeo.com/api/player.js", {
    onLoad: () => {
      hasVimeo();
    }
  });
}