/* eslint-disable */
import { leaveSitePrompt } from "../../leaveSitePrompt.js";

document.addEventListener("alpine:init", () => {
    Alpine.data("talentLmsLink", (config) => ({
        contextPath: config.contextPath,
        email: config.email,
        courseId: config.courseId,
        branchName: config.branchName,
        siteName: config.siteName,
        link: null,

        async gotoTalentLmsLink(e) {
            const target = e.target;
            const leaveSiteModal = document.querySelectorAll(".leaveSitePrompt");

            if (!this.email) {
                return;
            }

            const apiUrl = `${this.contextPath}/.rest/talentlms/v1/goToCourse?email=${encodeURIComponent(this.email)}&courseId=${this.courseId}&branchName=${this.branchName}`;

            fetch(apiUrl, {
                method: "GET",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "x-sny-siteoverride": this.siteName,
                }
            })
            .then(response => {
                return response.json();
            })
            .then((data) => {
                if (data?.entity?.goto_url) {
                    this.link = data.entity.goto_url;
                }

                if (leaveSiteModal && this.link) {
                    target.setAttribute("href", this.link);
                    leaveSitePrompt(e, target, leaveSiteModal);
                    target.setAttribute("href", "javascript:void(0)");
                } else {
                    window.open(this.link, "_blank");
                }
            })
            .catch((e) => {
                console.warn(e);
            });
        },
    }));
});