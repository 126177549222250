import Alpine from "alpinejs";

Alpine.magic("dateFormat", () => (date, format = "fulldate") => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let lang = document.documentElement.lang;
    let formattedDate = "";
    let options = {};
    switch (format) {
        case "fulldateshort": // e.g (en-EN) is "Jul 12, 2022"
            options = { year: "numeric", month: "short", day: "numeric" };
            formattedDate = date.toLocaleDateString(lang, options);
            break;
        case "fulldatenumeric": // e.g (en-EN) is "7/12/22"
            options = { year: "numeric", month: "numeric", day: "numeric" };
            formattedDate = date.toLocaleDateString(lang, options);
            break;
        case "monthyearletterlong": // e.g (en-EN) is "July 2022"
            options = { year: "numeric", month: "long" };
            formattedDate = date.toLocaleDateString(lang, options);
            break;
        case "monthyearlettershort": // e.g (en-EN) is "Jul 2022"
            options = { year: "numeric", month: "short" };
            formattedDate = date.toLocaleDateString(lang, options);
            break;
        case "monthyearnumeric": // e.g (en-EN) is "07/2022"
            options = { year: "numeric", month: "numeric" };
            formattedDate = date.toLocaleDateString(lang, options);
            break;
        case "year": // e.g (en-EN) is "2022"
            options = { year: "numeric" };
            formattedDate = date.toLocaleDateString(lang, options);
            break;
        case "monthname": // e.g (en-EN) is "July"
            options = { month: "long" };
            formattedDate = date.toLocaleDateString(lang, options);
            break;
        case "dayname": // e.g (en-EN) is "Tuesday"
            options = { weekday: "long" };
            formattedDate = date.toLocaleDateString(lang, options);
            break;
        case "dd": // e.g (en-EN) is "29"
            options = { day: "numeric" };
            formattedDate = date.toLocaleDateString(lang, options);
            break;
        case "timeWithTimeZone": // e.g (en-EN) is "07:00 AM CDT"
            options = { hour: "2-digit", minute: "2-digit", timeZone: timezone, timeZoneName: "short", hour12: true };
            formattedDate = date.toLocaleTimeString(lang, options);
            break;
        case "time": // e.g (en-EN) is "09:00 AM"
            options = { hour: "2-digit", minute: "2-digit", hour12: true };
            formattedDate = date.toLocaleTimeString(lang, options);
            break;
        default:
            // e.g (en-EN) is "August 26, 2022"
            options = { year: "numeric", month: "long", day: "numeric" };
            formattedDate = date.toLocaleDateString(lang, options);
    }
    return formattedDate;
});
