import { trackEvent } from "./track";
import { leaveSitePrompt } from "./leaveSitePrompt";
import { escapeQuotes } from "./helpers/stringHelpers";

const groomLinks = function () {
    document.body.addEventListener("click", function(e) {
        const target = getTargetLink(e.target);
        if (target) {
            const link = target.getAttribute("href");
            const leaveSiteModal = document.querySelectorAll(".leaveSitePrompt");
            switch (getLinkType(link)) {
                case "asset":
                    e.preventDefault();
                    window.open(link, target.target);
                    pushDownloadEvent(link);
                    break;
                case "external":
                    if (!target.classList.contains("leaveSitePrompt__button") && leaveSiteModal) { // If LeaveSitePrompt is Configured
                        leaveSitePrompt(e, target, leaveSiteModal);
                    } else{
                        if(!target.getAttribute("target")) { // Open External link on new tab as default if target is not set
                            e.preventDefault();
                            window.open(link, "_blank");
                        }
                        pushExternalLinkEvent(link);

                        if (target.dataset.linktype == "download") {
                            pushExternalDownloadEvent(target);
                        }
                    }

                    // Push footer datalayer when the list is also in footer
                    if (isFooterLink(target)) {
                        pushFooterLinkEvent(target);
                    }
                    break;
                case "internal":
                    if (target.closest(".rich-text")) { // if internal link is inside richtext
                        pushRichTextLinkEvent(target);
                    }

                    if (target.closest("header") && !/\\'track\\-event\\'/.test(target.getAttribute("@click"))) { // if internal link is inside header and doesnt have 'track-event' dispatch
                        pushHeaderLinkEvent(target);
                    } 
                    
                    if (isFooterLink(target)) { 
                        pushFooterLinkEvent(target);
                    }
                    break;
                default:
            }
        }
    });
}

const isFooterLink = (target) => target.closest("footer") && !/\\'track\\-event\\'/.test(target.getAttribute("@click")); // if internal link is inside footer and doesnt have 'track-event' dispatch

const getLinkType = function (link) {
    let origin = window.location.origin;
    let isInternal = new RegExp("^" + origin, "g");

    // is Hash
    if (/^\\#[-\w\d_\s]*$/g.test(link)) {
        return "hash";
    }

    // is mailto
    if (/mailto:([^\\?]*)g/.test(link)) {
        return "mailto";
    }

    // is tel
    if (/tel:([^\\?]*)g/.test(link)) {
        return "tel";
    }

    // is external
    if (/^((http|https):\/\/)?(([\w.-]*)\.([\w]))(\/[^\\?$]*)?/g.test(link) && !isInternal.test(link)) {
        return "external";
    }

    // is asset
    if (/^(\/(\.resources|\.imaging|dam|local\/dam|local\/\.resources|local\/\.imaging|author\/dam|author\/\.resources|author\/\.imaging)\/([\w\\/\-\\:\s\\%\\&\\#\\@]*).+)$/gi.test(link)) {
        return "asset";
    }

    // is internal
    if (/^(\/([\w\\/\-\\:\s\\%\\&\\#\\@\\?\\=]*)(.html|htm)*)$/gi.test(link) || isInternal.test(link)) {
        return "internal";
    }

    return null;
}

const getTargetLink = function (target) {
    let el = null;
    if (target.localName == "a") {
        el = target;
    } else {
        if (target.closest("a")) { // when anchor link has html tag inside
            el = target.closest("a");
        }
    }
    return el;
}

const getLinkSection = function (link, defaultValue) {
    let section = link.dataset.gtmsection;
    if (!section || section == "") {
        section = defaultValue;
    }
    return section;
}

const getLinkSubSection = function (link, defaultValue) {
    let subSection = link.dataset.gtmsubsection;
    if (!subSection || subSection == "") {
        subSection = defaultValue;
    }
    return subSection;
}

const getLinkElement = function (link) {
    let element = link.dataset.gtmelement;
    if (!element || element == "") {
        element = link.innerText;
        if (!element || element == "") {
            element = link.getAttribute("title"); // Use Link Title when link text is empty
        }
    }
    return element;
}

const pushRichTextLinkEvent = function (target) {
    let linkText = escapeQuotes(target.innerText);
    let obj = { event: "cmp_event", event_type: "cta", title: linkText, context: "others", eventCategory: "navigation", eventAction: linkText, eventLabel: "quick links" };
    let image = target.querySelector("img");
    if (image) { // if link has image
        obj.eventLabel = "logo";
        let imageAlt = escapeQuotes(image.getAttribute("alt"));
        if (linkText == "" && imageAlt != "") {
            obj.linkText = imageAlt;
            obj.eventAction = imageAlt;
        }
    }
    trackEvent(obj);
}

const pushDownloadEvent = function (link) {
    let fileName = link.split("/").pop();
    let fileFormat = fileName.split(".").pop();
    trackEvent({
        event: "download",
        name: fileName,
        document_format: fileFormat,
        interaction_type: "download"
    });
}

const pushExternalDownloadEvent = function (target) {
    trackEvent({
        event: "download",
        name: target.dataset.linktext || escapeQuotes(target.innerText),
        url: target.getAttribute("href"),
        interaction_type: "download"
    });
}

const pushExternalLinkEvent = function (link) {
    trackEvent({
        event: "outbound_link",
        url: link,
        interaction_type: "outbound_link"
    });
}

const pushHeaderLinkEvent = function (link) {
    const section = getLinkSection(link, "header");
    const subSection = getLinkSubSection(link, "menu");
    const element = getLinkElement(link);
    trackEvent({
        event: "navigation",
        section: section,
        sub_section: subSection,
        element: element,
        interaction_type: "navigation"
    });
}

const pushFooterLinkEvent = function (link) {
    const section = getLinkSection(link, "footer");
    const subSection = getLinkSubSection(link, "menu");
    const element = getLinkElement(link);
    trackEvent({
        event: "navigation",
        section: section,
        sub_section: subSection,
        element: element,
        interaction_type: "navigation"
    });
}

function init() {
    groomLinks();
}

export default { init, pushExternalDownloadEvent };
