function init() {
    appendModalsToBody();
    getSelectedTherapyAndSubmitForm();
    selectLetterAndSubmitForm();
    modalDialogEvent();
}

function modalDialogEvent() {
    var modalButtons = document.querySelectorAll(".change-z-index");
    const mainContent = document.querySelector("#main");
    [].forEach.call(modalButtons, (buttons) => {
        buttons.addEventListener(
            "click",
            () => {
                mainContent.style.zIndex = "auto";
            },
            false
        );
    });
}

function appendModalsToBody() {
    var body = document.querySelector("body");
    var modalsToMove = document.querySelectorAll(".append-to-footer");
    [].forEach.call(modalsToMove, (modal) => {
        body.append(modal);
    });
}

function getSelectedTherapyAndSubmitForm() {
    var therapyCheckboxes = document.querySelectorAll(".therapeuticIds");
    [].forEach.call(therapyCheckboxes, (checkbox) => {
        checkbox.addEventListener(
            "click",
            () => {
                const data = [...document.querySelectorAll(".therapeuticIds:checked")].map((e) => e.value);
                document.querySelector("#therapeuticIds").value = data.join(",");
                document.querySelector("#searchForm").submit();
            },
            false
        );
    });
}
function selectLetterAndSubmitForm() {
    var lettersButtons = document.querySelectorAll(".letter-btn");
    [].forEach.call(lettersButtons, (btn) => {
        btn.addEventListener(
            "click",
            () => {
                document.querySelector("#letter").value = btn.value;
                document.querySelector("#searchForm").submit();
            },
            false
        );
    });
}
export default { init };
