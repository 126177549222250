import Alpine from "alpinejs";
import { breakpointIs } from "../../breakpoint";

const dataFN = (config) => {
    const opts = { open: false, openMore: false, showMax: null, display: "tabs", activeTab: [], activeAccordion: [], activeAccordions: [], ...config };

    return {
        ...opts,
        getMax(value) {
            this.showMax = value;
        },
        showItem: ({ showMax, openMore, index }) => showMax >= index || openMore,
        openTabURL() {
            let tabs = this.$root.querySelectorAll(".tabComponent__tabList");
            tabs.forEach((el, index) => {
                let id = "#" + el.id;
                let hash = window.location.hash;
                if (hash === id) {
                    let openTab = "tab-" + index;
                    this.setActiveTab(openTab);
                }
            });
        },
        setActiveTab(id) {
            this.activeTab = id;
            //maintain state between views
            this.setActiveAccordion(id);
            this.toggleAccordion(id);

            const psWidget = this.$refs[id]?.querySelector(".ps-widget");
            if (psWidget && psWidget?.style?.display == "none") {
              window.PriceSpider?.rebind();
            }
        },
        setActiveAccordion(id) {
            this.activeAccordion = id;
        },
        isAccordionActive(id) {
            return this.activeAccordions.includes(id);
        },
        toggleAccordion(id) {
            //toggle accordion active if closed
            if (this.activeAccordions.includes(id)) {
                this.activeAccordion = "";
                //keep state between mobile/desktop views
                //if accordion on mobile changes, also change tab view on desktop
                if (this.activeTab === id) {
                    this.activeTab = "";
                }
            }
            if (this.isAccordionActive(id)) {
                // Remove this is from array
                this.activeAccordions = this.activeAccordions.filter((item) => item != id);
                // if no tab in accordion is open, set the last active tab to desktop
                if (this.activeAccordions.length == 0) {
                    this.activeTab = id;
                }
            } else {
                // add id to array
                this.activeAccordions = [id];
                //also make active tab
                this.activeTab = id;
            }
        },
        isOpen(id) {
            // Tabs mode
            if (this.display === "tabs") {
                return this.activeTab === id;
            }
            // MobileAccordion mode
            else {
                //if not mobile sized breakpoint
                if (breakpointIs("lg") || breakpointIs("xl")) {
                    return this.activeTab === id;
                } else {
                    return this.isAccordionActive(id);
                }
            }
        },
    };
};

Alpine.data("tabs", dataFN);
window.sui = window.sui || {};
window.sui.tabs = dataFN;
