import Alpine from "alpinejs";

import { embedYtScript } from "../modules/video/youtube";
import { embedBrightcoveScript, vjsPlayer } from "../modules/video/videojs";
import { damPlayer } from "../modules/video/dam";
import { vimeoPlayer } from "../modules/video/vimeo";

Alpine.magic("embedYtScript", () => (player, config = { autoplay: false }) => {    
    return embedYtScript(player, config);
});

Alpine.magic("embedBrightcoveScript", () => (video, config = { autoplay: false }) => { 
    return embedBrightcoveScript(video, config);
});

Alpine.magic("vjsPlayer", () => (video, config = { autplay: false }) => {     
    if(config.autoplay) {
        video.play();        
    }
    return vjsPlayer(video);
});

Alpine.magic("damPlayer", () => (video) => {
    return damPlayer(video);
});


Alpine.magic("vimeoPlayer", () => (ops) => {
    return vimeoPlayer(ops);
});