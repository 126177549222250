function init() {
    const navSearches = document.querySelectorAll(".navSearch--desktop");
    navSearches.forEach(function (navSearch) {
        openSearch(navSearch);
        closeSearch(navSearch);
        inputSearch(navSearch);
    });
}

function openSearch(navSearch) {
    const navSearchButton = navSearch.querySelector(".navSearch__button");
    const navSearchInput = navSearch.querySelector(".navSearch__input");
    navSearchButton.addEventListener("click", function () {
        toggleNavLeft();
        navSearchInput.focus();
    });
}

function closeSearch(navSearch) {
    const navSearchInput = navSearch.querySelector(".navSearch__input");
    navSearchInput.addEventListener("focusout", function () {
        toggleNavLeft();
    });
}

function inputSearch(navSearch) {
    const navSearchInput = navSearch.querySelector(".navSearch__input");
    const navSearchResult = navSearch.querySelector(".navSearch__result");
    navSearchInput.addEventListener("keyup", function (e) {
        if (e.target.value == "") {
            navSearchResult.classList.add("hidden");
        } else {
            navSearchResult.classList.remove("hidden");
        }
    });
}

function toggleNavLeft() {
    const body = document.querySelector("body");
    const navLeft = document.querySelector(".nav__left");
    const navRight = document.querySelector(".nav__right");
    if (navLeft) {
        navLeft.classList.toggle("hidden");
        navRight.classList.toggle("nav__right--openSearch");
        body.classList.toggle("navSearch--overlay");
    }
}

export default { init };
